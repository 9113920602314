import { Image, Modal } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { asyncWrap } from '../../utils/utils';
import axios from 'axios';
import CloseModalTab from '../../component/Nav/CloseModalTab';

const Brocher = () => {
  // eslint-disable-next-line
  const [show, setShow] = useState(true);
  const [cmsValues, setCmsValues] = useState();

  useEffect(() => {
    getCmsValues();
  }, []);

  const getCmsValues = async () => {
    const [error, result] = await asyncWrap(axios.get('/cmsValues'));
    if (!result) {
      console.log(error);
      return;
    }
    setCmsValues(result.data.data);
  };

  return (
    <>
      <Modal fullscreen={true} show={show}>
        <Modal.Body>
          <div className='d-flex flex-column'>
            {/* <CloseModalTab /> */}
            <div className='d-flex flex-row justify-content-evenly gap-2 flex-wrap'>
              {cmsValues &&
                cmsValues.Brochures.map((item, index) => (
                  <div
                    onClick={() => {
                      const newWindow = window.open(
                        item?.WebLink || item?.PDFPath
                      );
                      if (newWindow) newWindow.opener = null;
                    }}
                    className='d-flex flex-column rounded-2 shadow-sm p-1'
                    style={{ width: "48%",height: 'auto' }}
                  >
                    <Image
                      src={item.BannerImage}
                      style={{
                        width: '100%',
                        marginTop: 'auto',
                        marginBottom: 'auto',
                      }}
                    />
                  </div>
                  // <div
                  //   key={index}
                  //   onClick={() => {
                  //     const newWindow = window.open(
                  //       item?.WebLink || item?.PDFPath
                  //     );
                  //     if (newWindow) newWindow.opener = null;
                  //   }}
                  //   style={{ borderRadius: "20px" }}
                  //   className="d-flex flex-column border mt-3"
                  // >
                  //   <div
                  //     style={{
                  //       height: "150px",
                  //       background: "#E5ECFD",
                  //       borderRadius: "15px",
                  //     }}
                  //     className="d-flex justify-content-center"
                  //   >
                  //     <img
                  //       alt="brocherimg"
                  //       style={{
                  //         maxWidth: "100%",
                  //         height: "100%",
                  //       }}
                  //       src={item.BannerImage}
                  //     />
                  //   </div>
                  // </div>
                ))}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Brocher;
